/* eslint-disable no-unused-expressions */
import React from "react"
import { graphql } from "gatsby"

import Section from "../components/wwc/layout/Section/Section"
import Row from "../components/wwc/layout/Row/Row"
import Column from "../components/wwc/layout/Column/Column"
import Text from "../components/wwc/text/Text/Text"

const OfficialRules = ({ data, ...props }) => {
  return (
    <>
      <Section id="official-rules" className="section__legal">
        <Row>
          <Column>
            <Text html>{data.officialRulesJson.content.text}</Text>
          </Column>
        </Row>
      </Section>
    </>
  )
}

export const query = graphql`
  query OfficialRules($locale: String) {
    siteJson(locale: { eq: $locale }) {
      ...SiteData
    }
    officialRulesJson(locale: { eq: $locale }) {
      content {
        text
      }
    }
  }
`

export default OfficialRules
